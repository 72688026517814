<template>
  <div id="manageDriver" class="Content-Page">
    <div id="namePage" class="wrap-Main">
      <div class="box-S4 flex-between-center">
        <div class="N-Page T-size-36">Driver</div>
        <v-btn class="theme-btn-even" @click.stop="addItem()">
          <span class="I-create"></span>
          <span>Add Driver</span>
        </v-btn>
      </div>
    </div>
    <div id="tableData" class="wrap-Main">
      <div class="box-S4">
        <v-data-table class="TF" :headers="headers" :items="items" hide-default-footer>
          <template v-slot:item.d_license_expire="{ item }">
           {{item.d_license_expire | yyyyMMddHHmm}}
          </template>
          <template v-slot:item.action="{ item }">
            <div class="b-action">
              <div class="b-btn">
                <v-btn fab class="btn-edit" @click="editItem(item)">
                  <span class="I-edit"></span>
                </v-btn>
                <v-btn fab class="primary btn-delete" @click="deleteItem(item)">
                  <span class="I-bin"></span>
                </v-btn>
              </div>
            </div>
          </template>
        </v-data-table>
      </div>
    </div>

    <v-dialog v-model="dialog" max-width="500px">
      <v-card>
        <v-card-title>
          <span class="headline">{{ formTitle }}</span>
        </v-card-title>
        <v-card-text>
          <v-container grid-list-md>
            <v-layout wrap>
              <v-flex lg12>
                <v-text-field v-model="formModel.d_name" label="Name"></v-text-field>
              </v-flex>
              <v-flex lg6>
                <v-text-field v-model="formModel.d_type_car" label="Type Car"></v-text-field>
              </v-flex>
              <v-flex lg6>
                <v-menu
                  v-model="menu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="LicenseExpire"
                      label="License Expire"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="LicenseExpire" @input="menu = false"></v-date-picker>
                </v-menu>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="ColorTheme-S white--text btn-size theme-btn" @click="closeDialog">Cancel</v-btn>
          <v-btn
            class="ColorTheme-S white--text btn-size theme-btn"
            :loading="loading"
            @click="saveToServer"
          >Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card>
        <v-card-title>
          <span class="headline">Delete Confirmation</span>
        </v-card-title>
        <v-card-text>
          <v-container grid-list-md>
            <v-layout wrap>
              <v-flex>Delete "{{formModel[Object.keys(formModel)[1]]}}" ?</v-flex>
            </v-layout>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="ColorTheme-S white--text btn-size theme-btn" @click="closeDialog">Cancel</v-btn>
          <v-btn
            class="ColorTheme-S white--text btn-size theme-btn"
            :loading="loading"
            @click="deleteToServer"
          >Delete</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    
    <div id="partBack" class="wrap-Main">
      <div class="line"></div>
      <div class="box-S4">
        <v-btn text class="ColorTheme-S white--text theme-btn" @click="Back()">
          <span class="I-back"></span>
          <span>Back</span>
        </v-btn>
      </div>
    </div>

  </div>
</template>


<script>
import feathersClientVan from "../plugins/feathers-client";
import fillerdate from "../utils/filterdate";
export default {
  data: () => ({
    headers: [
      { value: "d_name", text: "Driver Name", sortable: true },
      { value: "d_type_car", text: "Type Car", sortable: true },
      { value: "d_license_expire", text: "License Expire", sortable: true },
      { text: "", value: "action", sortable: false }
    ],
    defaultValue: {
      d_name: ""
    },
    query: { $sort: { Id: -1 } },
    LicenseExpire: new Date().toISOString().substr(0, 10),
    menu: false,
    items: [],
    total: 0,
    loading: false,
    dialog: false,
    dialogDelete: false,
    formModel: {},
    mode: "" // มีได้ 2 แบบคือ create กับ edit
  }),
  computed: {
    formTitle() {
      return this.mode === "create" ? "Add" : "Edit";
    }
  },
  async mounted() {
    this.renderUI();
  },
  methods: {
    Back() {
      this.$router.go(-1);
    },
    async renderUI() {
      try {
        const q = {};
        q.retire = 0;
        var res = await feathersClientVan.service("driver").find({ query: q });
        this.total = res.total;
        this.items = res.data;
      } catch (error) {
        console.log(error);
        alert("ไม่สามารถขอข้อมูลจาก server ได้");
      }
    },
    async addItem() {
      this.mode = "create";
      this.formModel = Object.assign({}, this.defaultValue);
      this.dialog = true;
    },
    async editItem(item) {
      this.mode = "edit";
      this.formModel = Object.assign({}, item);
      this.dialog = true;
    },
    async deleteItem(item) {
      this.formModel = Object.assign({}, item);
      this.dialogDelete = true;
    },
    closeDialog() {
      this.dialog = false;
      this.dialogDelete = false;
    },
    async saveToServer() {
      this.loading = true;
      if (this.mode === "edit") {
        try {
          let dataEdit = {
            d_name: this.formModel.d_name,
            d_type_car: this.formModel.d_type_car,
            d_license_expire: this.LicenseExpire,
            mb: "Aun2",
            md: new Date()
          };
          await feathersClientVan
            .service("driver")
            .patch(this.formModel.d_id, dataEdit);
          this.renderUI();
        } catch (err) {
          console.log(err);
        } finally {
          this.loading = false;
        }
      } else {
        try {
          let data = {
            d_name: this.formModel.d_name,
            d_type_car: this.formModel.d_type_car,
            d_license_expire: this.LicenseExpire,
            cb: "Aun",
            cd: new Date(),
            retire: 0
          };
          await feathersClientVan.service("driver").create(data);
          this.renderUI();
        } catch (err) {
          console.log(err);
        } finally {
          this.loading = false;
        }
      }
      this.closeDialog();
    },
    async deleteToServer() {
      this.loading = true;
      try {
        let dataEdit = {
          mb: "Aun3",
          md: new Date(),
          retire: 1
        };
        await feathersClientVan
          .service("driver")
          .patch(this.formModel.d_id, dataEdit);
        this.renderUI();
      } catch (err) {
        console.log(err);
      } finally {
        this.loading = false;
        this.dialogDelete = false;
      }
    }
  }
};
</script> 

